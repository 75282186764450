import { Form } from "./Form";
import { Courses } from "./Courses";
import { StyledContent } from "./styles";
import { useEffect, useState } from "react";
import { Loading } from "components/Loading";

const Content = () => {
  const [title, setTitle] = useState("Black Friday Exclusiva para Alunos");
  const [showContent, setShowContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [courseslist, setCoursesList] = useState<number[]>([]);
  const [email, setEmail] = useState<string>("");
  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const emailParams = urlParams.get("email");

  const formId = "11cd85a2-5fd8-42c0-b90d-2fe78cfd377b";

  const handleSubmitResponse = async (data: Response) => {
    const responseArray = await data.json();
    if (data.status === (404 || 422 || 451)) {
      setCoursesList([3, 1]);
      setShowContent(true);
    } else if (!!responseArray.data.length) {
      setCoursesList(responseArray.data);
      setShowContent(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (emailParams) {
      const isValid = validarEmail(emailParams);
      if (isValid) {
        setEmail(emailParams);
      }
    }
  }, [emailParams]);

  useEffect(() => {
    courseslist.length === 0
      ? setTitle("Black Friday Exclusiva para Alunos")
      : courseslist.length === 4
      ? setTitle("Nenhuma oferta disponível")
      : setTitle("Ofertas disponíveis para você");
  }, [courseslist]);

  const validarEmail = (email: string) => {
    // Expressão regular para validar o formato do e-mail
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Testa o e-mail usando a expressão regular
    return regexEmail.test(email);
  };

  return (
    <main>
      <div
        id="hubspotForm"
        style={{
          display: "none",
        }}
      ></div>
      <StyledContent>
        <div>
          <h1>
            <span>{title}</span>
          </h1>
        </div>
        {loading && <Loading />}
        {(!emailParams || email === "") && <Courses alredyHave={[3, 1]} />}
        {email && (
          <Form
            handleSubmitResponse={handleSubmitResponse}
            setLoading={setLoading}
            email={email}
          />
        )}
        {showContent && !loading && <Courses alredyHave={courseslist} />}
      </StyledContent>
    </main>
  );
};

export { Content };
