import { Card } from "./Card";
import FullCycleLogo from "../../../assets/logos/fullCycle3.png";
import MBALogo from "../../../assets/logos/mbaFullCycle.png";
import PosGoLogo from "../../../assets/logos/posGo.png";
import { CourseGrid, CourseGridItem } from "./styles";
import * as environment from "environment";
import { corusesData } from "./Card/data";

interface CoursesProps {
  alredyHave: number[];
}
const Courses = ({ alredyHave }: CoursesProps) => {
  return (
    <CourseGrid>
      {corusesData.map((data, index) => {
        if (!alredyHave.includes(data.id)) {
          return (
            <CourseGridItem key={index}>
              <Card {...data} />
            </CourseGridItem>
          );
        }
      })}
    </CourseGrid>
  );
};

export { Courses };
