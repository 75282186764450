import { useEffect, useState } from "react";
import { FormContainer, StyledButton, StyledForm } from "./styles";
import { API_URL } from "environment";

interface FormProps {
  handleSubmitResponse: (data: Response) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  email: string;
}

export const Form = ({
  handleSubmitResponse,
  setLoading,
  email,
}: FormProps) => {
  const formId = "11cd85a2-5fd8-42c0-b90d-2fe78cfd377b";

  const getProductsbyUser = async () => {
    setLoading(true);
    const response = await fetch(
      `${API_URL}products-ids-by-user-email-with-inactive-enrollments?email=${email}`,
      {
        method: "GET",
      }
    );

    return response;
  };

  useEffect(() => {
    const scriptMain = document.createElement("script");
    scriptMain.charset = "utf-8";
    scriptMain.type = "text/javascript";
    scriptMain.src = "//js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(scriptMain);
    scriptMain.addEventListener("load", () => {
      //@ts-ignore
      if (window.hbspt) {
        //@ts-ignore
        window.hbspt.forms.create({
          portalId: "42671822",
          formId: formId,
          target: "#hubspotForm",
          region: "na1",
        });
      }
    });

    if (email) {
      handleSubmit(email);
    }
  }, []);

  const handleSubmit = (email: string) => {
    const hubspotInput = document.getElementById(
      `email-${formId}`
    ) as HTMLFormElement;
    const hubspotForm = document.getElementById(
      `hsForm_${formId}`
    ) as HTMLFormElement;
    if (hubspotInput && hubspotForm) {
      hubspotInput.value = email;
    }
    if (hubspotForm) {
      hubspotForm.submit();
    }

    getProductsbyUser().then((data) => handleSubmitResponse(data));
  };

  return (
    <>
      <div
        id="hubspotForm"
        style={{
          display: "none",
        }}
      ></div>
    </>
  );
};
