export const ENV = process.env;

export const API_URL = ENV.REACT_APP_API;

export const fcFullPrice = ENV.REACT_APP_FC_FULLPRICE as string;
export const fcPriceWithDiscount = ENV.REACT_APP_FC_PRICEWITHDISCOUNT as string;
export const fcInstallmentsQty = ENV.REACT_APP_FC_INSTALLMENTS_QTY as string;
export const fcInstallments = ENV.REACT_APP_FC_INSTALLMENTS as string;
export const fcCartLink = ENV.REACT_APP_FC_CART_LINK as string;

export const MBAFullPrice = ENV.REACT_APP_MBA_FULLPRICE as string;
export const MBAPriceWithDiscount =
  ENV.REACT_APP_MBA_PRICEWITHDISCOUNT as string;
export const MBAInstallmentsQty = ENV.REACT_APP_MBA_INSTALLMENTS_QTY as string;
export const MBAInstallments = ENV.REACT_APP_MBA_INSTALLMENTS as string;
export const MBACartLink = ENV.REACT_APP_MBA_CART_LINK as string;

export const PosGoFullPrice = ENV.REACT_APP_POSGO_FULLPRICE as string;
export const PosGoPriceWithDiscount =
  ENV.REACT_APP_POSGO_PRICEWITHDISCOUNT as string;
export const PosGoInstallmentsQty =
  ENV.REACT_APP_POSGO_INSTALLMENTS_QTY as string;
export const PosGoInstallments = ENV.REACT_APP_POSGO_INSTALLMENTS as string;
export const PosGoCartLink = ENV.REACT_APP_POSGO_CART_LINK as string;
